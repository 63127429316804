import {
    AppComponent as SourceAppComponent,
} from 'SourceComponent/App/App.component';

/** @namespace Celtik/Component/App/Component */
export class AppComponent extends SourceAppComponent {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    injectComment(): void {
        const comment = document.createComment('Morhane');

        document.querySelector('html')?.appendChild(comment);
    }
}

export default AppComponent;
