/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

// This file created after failing to create a plugin around onSuccess,
// don't know why it works only if you ovveride the file, I suspect smth related to pwa version.
import { showNotification } from '@scandipwa/scandipwa/src/store/Notification/Notification.action';
import { NotificationType } from '@scandipwa/scandipwa/src/type/NotificationList.type';
import LandingPageQuery from '../../query/LandingPage.query';
import { appendPage } from './LandingPage.action';
import QueryDispatcher from '@scandipwa/scandipwa/src/util/Request/QueryDispatcher';

/**
 * Product List Dispatcher
 * @class LandingPageDispatcher
 * @extends QueryDispatcher
 * @namespace Pwa/Store/LandingPage/Dispatcher */
export class LandingPageDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct('LandingPage');
    }

    onSuccess(
        data,
        dispatch,
        options,
    ) {
        const { amlanding } = data;

        return dispatch(appendPage(amlanding));
    }

    onError(error, dispatch) {
        dispatch(showNotification(NotificationType.ERROR, __('Error fetching anding Page!'), error));
    }

    prepareRequest(
        options,
        dispatch,
    ) {
        return LandingPageQuery.getQuery(options);
    }
}

export default new LandingPageDispatcher();
