import { cloneElement, Suspense } from 'react';
import { ProductType } from 'Component/Product/Product.config';
import { lowPriorityLazy } from 'Util/Request/LowPriorityRender';
import ProductListQuery from 'Query/ProductList.query';
import { fetchQuery } from 'Util/Request/Query';
import { getIndexedProduct } from 'Util/Product';
import { filterConfigurableOptions } from 'Util/Product';
import BrowserDatabase from 'Util/BrowserDatabase';

export const PARAMETERS = 'PARAMETERS';

export const PARENT_PRODUCT = 'PARENT_PRODUCT';

export const ProductConfigurableAttributes = lowPriorityLazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "product-misc" */ 'Component/ProductConfigurableAttributes/ProductConfigurableAttributes.container'),
);

/** @namespace Efex/MorhanePdpStock/Plugin/Route/ProductPage/renderShortDescription */
export const componentDidMount = (args, callback, instance) => {
    callback.apply(instance, args);

    if (instance.className === 'ProductActions') {
        const {
            product,
            product: { id, type_id: type, parent_id: parentId },
        } = instance.props;

        if ((type === ProductType.SIMPLE || type === ProductType.VIRTUAL) && parentId !== null) {
            getParentProduct(instance, parentId);
        } else if (type === ProductType.CONFIGURABLE) {
            getParentProduct(instance, id);
        }
    }
};

/** @namespace Efex/MorhanePdpStock/Plugin/Route/ProductPage/renderShortDescription */
export const componentDidUpdate = (args, callback, instance) => {
    callback.apply(instance, args);

    if (instance.className === 'ProductActions') {
        const { product: { sku: prevSku }, } = args[0];
        const { product, product: { id, sku, type_id: type, parent_id: parentId }, } = instance.props;

        if (prevSku !== sku) {
            if ((type === ProductType.SIMPLE || type === ProductType.VIRTUAL) && parentId !== null) {
                getParentProduct(instance, parentId);
            } else if (type === ProductType.CONFIGURABLE) {
                getParentProduct(instance, id);
            }
        }
    }
};

const setParentProductOnBrowserDB = (instance, productID, parentProduct) => {
    const parentProductData = {
        parentID: productID,
        parentProduct: parentProduct,
    };

    const { configurable_options: configurableOptions = {}, variants: parentVariants = [] } = parentProduct;
    const parentConfigurableOptions = filterConfigurableOptions(configurableOptions, parentVariants);
    instance.setState({ configurableOptions: parentConfigurableOptions, parentVariants });

    BrowserDatabase.setItem(parentProductData, PARENT_PRODUCT);
};

/** @namespace Efex/MorhanePdpVariants/Plugin/Route/ProductPage/getParentProduct */
const getParentProduct = async (instance, productID) => {
    /** @namespace Efex/MorhanePdpVariants/Plugin/Route/ProductPage/getParentProduct */
    try {
        const { parentID, parentProduct: cachedParentProduct } = BrowserDatabase.getItem(PARENT_PRODUCT) || {};

        if (productID === parentID){
            const { configurable_options: configurableOptions = {}, variants: parentVariants = [] } = cachedParentProduct;
            const parentConfigurableOptions = filterConfigurableOptions(configurableOptions, parentVariants);
            instance.setState({ configurableOptions: parentConfigurableOptions, parentVariants });

            return;
        }

        const { products: { items } } = await fetchQuery(ProductListQuery.getQuery({
            args: {
                filter: {
                    productID,
                },
            },
            isSingleProduct: true,
        }));

        const [product] = items;
        const parentProduct = getIndexedProduct(product);

        const { configurable_options: configurableOptions = {}, variants: parentVariants = [] } = parentProduct;
        const parentConfigurableOptions = filterConfigurableOptions(configurableOptions, parentVariants);
        instance.setState({ configurableOptions: parentConfigurableOptions, parentVariants });

        const parentProductData = {
            parentID: productID,
            parentProduct: parentProduct,
        };
        BrowserDatabase.setItem(parentProductData, PARENT_PRODUCT);
    } catch (e) {
        return null;
    }
};

/** @namespace Efex/MorhanePdpVariants/Plugin/Route/ProductPage/renderConfigurableOptions */
export const renderConfigurableOptions = (args, callback, instance) => {
    const originalElement = callback.apply(instance, args);

    if (instance.className === 'ProductActions') {
        const {
            setActiveProduct,
            parameters,
            product: { id, type_id: type, variants = [] },
            inStock,
            addToCartTriggeredWithError,
            updateAddToCartTriggeredWithError,
        } = instance.props;

        if (type !== ProductType.CONFIGURABLE && type !== ProductType.SIMPLE && type !== ProductType.VIRTUAL) {
            return null;
        }

        const {
            configurableOptions, parentVariants
        } = instance.state;

        return (
            <div
              block={ instance.className }
              elem="AttributesWrapper"
            >
                <Suspense fallback={ null }>
                    <ProductConfigurableAttributes
                      // eslint-disable-next-line no-magic-numbers
                      numberOfPlaceholders={ [2, 4] }
                      updateAddToCartTriggeredWithError={ updateAddToCartTriggeredWithError }
                      addToCartTriggeredWithError={ addToCartTriggeredWithError }
                      mix={ { block: instance.className, elem: 'Attributes' } }
                      parameters={ parameters }
                      variants={ (type === ProductType.SIMPLE || type === ProductType.VIRTUAL) ? parentVariants : variants }
                      updateConfigurableVariant={ setActiveProduct }
                      configurable_options={ (type === ProductType.SIMPLE || type === ProductType.VIRTUAL) ? configurableOptions : instance.getConfigurableAttributes() }
                      isContentExpanded
                      inStock={ inStock }
                      showProductAttributeAsLink={ false }
                      productId={ id }
                    />
                </Suspense>
            </div>
        );
    }

    return cloneElement(originalElement);

};

/** @namespace Efex/MorhanePdpVariants/Plugin/Component/ProductGallery/state */
export const state = (originalMember, instance) => ({
    ...originalMember,
    configurableOptions: {},
    parentVariants: [],
});

export default {
    'Component/Product/Component': {
        'member-function': {
            renderConfigurableOptions,
            componentDidMount,
            componentDidUpdate,
        },
        'member-property': {
            state,
        }
    },
};
