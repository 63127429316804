import domToReact from 'html-react-parser/lib/dom-to-react';

import ExpandableContent from 'Component/ExpandableContent';

export const replaceDiv = (args, callback, instance) => {
    const { attribs, children } = args[0];

    if (attribs?.class === 'Footer-Column') {
        const child = children.reduce((acc, child, index) => {
            const { attribs: attrs, children: childrenText } = child;

            if (attrs?.class === 'Footer-ColumnTitle') {
                const { type, data } = childrenText[0];

                if (type === 'text' && data) {
                    acc = {
                        title: data,
                        children: children.slice(index + 1),
                    };
                }
            }

            return acc;
        }, {});

        return (
            <ExpandableContent
              heading={ child.title }
              mix={ { block: 'FooterColumn', elem: 'Wrapper' } }
            >
                { domToReact(child.children, instance.parserOptions) }
            </ExpandableContent>
        );
    }

    return callback.apply(instance, args);
};

export default {
    'Component/Html/Component': {
        'member-function': {
            replaceDiv,
        },
    },
};
