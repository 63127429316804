/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { sha256 } from 'js-sha256';

import getStore from 'Util/Store';

import { DL_VAL_NOT_APPLICABLE } from '../data/common';
import { getCustomerData } from '../data/customer';
import { getPageData } from '../data/page';
import { GTM_EVENT_KEY_GENERAL } from '../util/events';
import { getLanguageNameByCode } from '../util/languageName';
import { pushToDataLayer } from '../util/push';
import { debounceCallback, waitForCallback } from '../util/wait';

/** @namespace Scandiweb/Gtm/Event/General/fireGeneralEvent */
export const fireGeneralEvent = debounceCallback(async (urlRewriteData) => {
    const pageData = await getPageData(urlRewriteData);

    await waitForCallback(() => getStore().getState().ConfigReducer?.code);
    await waitForCallback(() => getStore().getState().ConfigReducer?.locale);

    const {
        userLoginState,
        customerId,
        customerEmail,
        userExistingCustomer,
        userLifetimeValue,
        userLifetimeOrders,
    } = await getCustomerData();

    pushToDataLayer({
        event: GTM_EVENT_KEY_GENERAL,
        user_login_state: userLoginState,
        customer_id: customerId || null,
        customer_email: customerEmail,
        ...(customerEmail === DL_VAL_NOT_APPLICABLE ? {} : { customer_hashed_email: sha256(customerEmail) }),
        user_existing_customer: userExistingCustomer,
        user_lifetime_value: userLifetimeValue,
        user_lifetime_orders: userLifetimeOrders,
        ...pageData,
        store_view: getStore().getState().ConfigReducer?.code || DL_VAL_NOT_APPLICABLE,
        language: getLanguageNameByCode(getStore().getState().ConfigReducer?.locale),

    });
});
