import history from 'Util/History';
import { getNewParameters, getVariantIndex } from 'Util/Product';
import { ProductType } from 'Component/Product/Product.config';
import BrowserDatabase from 'Util/BrowserDatabase';

export const PARAMETERS = 'PARAMETERS';

export const PARENT_PRODUCT = 'PARENT_PRODUCT';

/** @namespace Efex/MorhanePdpVariants/Plugin/Route/ProductPage/componentDidMount */
export const componentDidMount = (args, callback, instance) => {
    callback.apply(instance, args);

    const { isPlp } = instance.props;
    if (!isPlp) {
        onUpdateParameters(instance);
    }
};

/** @namespace Efex/MorhanePdpVariants/Plugin/Route/ProductPage/componentDidUpdate */
export const componentDidUpdate = (args, callback, instance) => {
    callback.apply(instance, args);

    const { product: { sku: prevSku }, } = args[0];
    const { isPlp = false, product, product: { sku, configurable_options }, } = instance.props;

    if (!isPlp && Object.keys(product).length) {
        onUpdateParameters(instance);
    }
};

const onUpdateParameters = (instance) => {
    const { parameters: prevParameters = {} } = instance.state;
    const { product: { type_id: type, configurable_options = {} } } = instance.props;

    if (Object.keys(prevParameters).length === 0 && Object.keys(configurable_options).length !== 0 && type !== ProductType.CONFIGURABLE) {
        const { product: { configurable_options } } = instance.props;
        const parameters = Object.entries(configurable_options).reduce((parameters, [code, { attribute_value }]) => {
            if (attribute_value) {
                return { ...parameters, [ code ]: attribute_value };
            }
            return parameters;
        }, {});

        instance.setState({ parameters });

        setTimeout(() => {
            onUpdateParameters(instance);
        }, 500);
    }
};

/** @namespace Efex/MorhanePdpVariants/Plugin/Route/ProductPage/updateConfigurableVariant */
export const updateConfigurableVariant = (args, callback, instance) => {
    const key = args[0];
    const value = args[1];
    const checkEmptyValue = args[2] || false;

    const { parameters: prevParameters } = instance.state;

    const newParameters = getNewParameters(prevParameters, key, value);

    const { [ key ]: oldValue, ...currentParameters } = newParameters;
    const parameters = oldValue === '' && checkEmptyValue ? currentParameters : newParameters;

    const { product: { parent_id } } = instance.props;

    let cVariants = [];
    let configurableOptions = [];

    let parentId = 0;
    if (parent_id === null) {
        const { product: { id, variants = [], configurable_options = {} } } = instance.props;
        parentId = id;

        cVariants = variants;
        configurableOptions = configurable_options;
    } else {
        const { parentID, parentProduct } = BrowserDatabase.getItem(PARENT_PRODUCT) || {};
        parentId = parentID;

        if (parent_id === parentID) {
            const { variants = [], configurable_options = {} } = parentProduct;

            cVariants = variants;
            configurableOptions = configurable_options;
        }
    }

    const unselectedOptions = Object.keys(configurableOptions).reduce((accumulator, value) => {
        if (!parameters[ value ]) {
            accumulator.push(value);
        }

        return accumulator;
    }, []);


    unselectedOptions.map((key) => {
        const { attribute_values: values } = configurableOptions[key];
        parameters[key] = values[0];
    });

    const newIndex = Object.keys(parameters).length === Object.keys(configurableOptions).length
        ? getVariantIndex(cVariants, parameters)
        : -1;

    const newProduct = newIndex === -1 ? null : cVariants[ newIndex ];

    if (newProduct !== null) {
        const { canonical_url: pathname } = newProduct;
        history.push({ pathname });
    }

};

export default {
    'Component/Product/Container': {
        'member-function': {
            componentDidMount,
            componentDidUpdate,
            updateConfigurableVariant,
        },
    },
};
