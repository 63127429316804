/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    appendPage,
    updateProductListItems,
} from 'Store/ProductList/ProductList.action';

import { getCustomerData } from '../../data/customer';

const aroundOnSuccess = async (functionArgs) => {
    const [data, dispatch, options] = functionArgs;
    const {
        products: {
            items,
            total_count,
            page_info: { total_pages } = {},
        } = {},
    } = data;

    const { args, isNext } = options;
    const { currentPage } = args;

    if (isNext) {
        return dispatch(
            appendPage(
                items,
                currentPage
            )
        );
    }

    const { customerId } = await getCustomerData();

    return dispatch(
        {
            ...updateProductListItems(
                items,
                currentPage,
                total_count,
                total_pages,
                args,

            ),
            customerId,
        }
    );
};

export default {
    'Store/ProductList/Dispatcher': {
        'member-function': {
            onSuccess: aroundOnSuccess,
        },
    },
};
