/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
/* eslint-disable max-lines */
import { ReactElement } from '@scandipwa/scandipwa/src/type/Common.type';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
    UrlRewritesContainer as SourceUrlRewritesContainer,
} from 'SourceRoute/UrlRewrites/UrlRewrites.container';
import {
    UrlRewritesContainerMapDispatchProps,
    UrlRewritesContainerMapStateProps,
} from 'SourceRoute/UrlRewrites/UrlRewrites.type';
import UrlRewritesDispatcher from 'Store/UrlRewrites/UrlRewrites.dispatcher';
import { RootState } from 'Util/Store/Store.type';

import UrlRewrites from './UrlRewrites.component';

/** @namespace Celtik/Route/UrlRewrites/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): UrlRewritesContainerMapStateProps => ({
    urlRewrite: state.UrlRewritesReducer.urlRewrite,
    isLoading: state.UrlRewritesReducer.isLoading,
    requestedUrl: state.UrlRewritesReducer.requestedUrl,
});

/** @namespace Celtik/Route/UrlRewrites/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch): UrlRewritesContainerMapDispatchProps => ({
    requestUrlRewrite: (urlParam) => UrlRewritesDispatcher.handleData(dispatch, { urlParam }),
});

/** @namespace Celtik/Route/UrlRewrites/Container */
export class UrlRewritesContainer extends SourceUrlRewritesContainer {
    componentDidMount(): void {
        // Removed onMount condition to populate UrlRewriteReducer when accessing pages directly
        this.requestUrlRewrite();

        this.initialUrl = location.pathname;
    }

    render(): ReactElement {
        const props = { ...this.containerProps(), id: this.props?.urlRewrite?.id };

        return (
            <UrlRewrites
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...props }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UrlRewritesContainer);
