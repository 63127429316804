/* eslint-disable comma-dangle */
/* eslint-disable quote-props */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-arrow-functions-in-class */
import {
    updateBreadcrumbs,
} from 'Store/Breadcrumbs/Breadcrumbs.action';

export class BreadcrumbsDispatcherPlugin {
    updateWithCmsPage(_args, _callback, _instance) {
        const { title, url = '' } = _args[0];
        const dispatch = _args[1];
        const breadcrumbs = title
            ? [
                {
                    url,
                    name: title,
                },
            ]
            : [];

        dispatch(updateBreadcrumbs(breadcrumbs));
    }
}

const {
    updateWithCmsPage,
} = new BreadcrumbsDispatcherPlugin();

export default {
    'Store/Breadcrumbs/Dispatcher': {
        'member-function': {
            updateWithCmsPage,
        },
    },
};
