Mosaic.addPlugins([require('/var/www/vhosts/morhane.com/httpdocs/pwa/celtik/packages/@efex/morhane-seo/src/plugin/NoMatchContainer.plugin.js'),require('/var/www/vhosts/morhane.com/httpdocs/pwa/celtik/packages/@efex/morhane-seo/src/plugin/NoMatchContainer.plugin.js')]);
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import LandingPage from './LandingPage.component';
import BreadcrumbsDispatcher from '@scandipwa/scandipwa/src/store/Breadcrumbs/Breadcrumbs.dispatcher';
import { updateMeta } from '@scandipwa/scandipwa/src/store/Meta/Meta.action';
import { fetchQuery } from '@scandipwa/scandipwa/src/util/Request/Query';
import ProductListDispatcher from '@scandipwa/scandipwa/src/store/ProductList/ProductList.dispatcher';
import ProductListInfoDispatcher from '@scandipwa/scandipwa/src/store/ProductListInfo/ProductListInfo.dispatcher';
import { getQueryParam, setQueryParams } from '@scandipwa/scandipwa/src/util/Url';
import history from '@scandipwa/scandipwa/src/util/History';
import { CategoryPageLayout, LAYOUT_KEY, SortDirections } from '@scandipwa/scandipwa/src/route/CategoryPage/CategoryPage.config';
import LandingPageQuery from '../query/LandingPage.query';
import BrowserDatabase from '@scandipwa/scandipwa/src/util/BrowserDatabase';
import { CATEGORY_FILTER_OVERLAY_ID } from '@scandipwa/scandipwa/src/component/CategoryFilterOverlay/CategoryFilterOverlay.config';
import { toggleOverlayByKey } from '@scandipwa/scandipwa/src/store/Overlay/Overlay.action';
import { getFiltersCount } from '@scandipwa/scandipwa/src/util/Category';
import LandingPageDispatcher from '../store/LandingPage/LandingPage.dispatcher';

/** @namespace Route/NoMatch/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateBreadcrumbs: (breadcrumbs) => BreadcrumbsDispatcher.update(breadcrumbs, dispatch),
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    requestProductList: (options) => ProductListDispatcher.handleData(dispatch, options),
    requestProductListInfo: (options) => ProductListInfoDispatcher.handleData(dispatch, options),
    requestLandingPage: (options) => LandingPageDispatcher.handleData(dispatch, options),
    toggleOverlayByKey: (key) => dispatch(toggleOverlayByKey(key)),
});

/** @namespace Route/NoMatch/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile,
    filters: state.ProductListInfoReducer.filters,
    sortFields: state.ProductListInfoReducer.sortFields,
    products: state.ProductListReducer.pages,
    totalPages: state.ProductListReducer.totalPages,
    selectedInfoFilter: state.ProductListInfoReducer.selectedFilter,
    landingpage: state.LandingPageReducer.landingpage,
});

export class LandingPageContainer extends PureComponent {
    state = {
        selectedLayoutType: undefined,
        landinpage: {},
        isloaded: false
    }

    containerFunctions = {
        onSortChange: this.onSortChange.bind(this),
        onGridButtonClick: this.onGridButtonClick.bind(this),
        onListButtonClick: this.onListButtonClick.bind(this),
        onFilterButtonClick: this.onFilterButtonClick.bind(this),
    };

    componentDidMount() {
        this.fetchLandingPage();
    }

    componentDidUpdate() {
        const { landingpage } = this.props;
        const { isloaded } = this.state;

        if (landingpage && !isloaded) {
            this.setState({
                landinpage: landingpage,
                isloaded: true
            });

            this.updateMeta(landingpage);
            this.updateBreadcrumbs(landingpage);
        }
    }

    onFilterButtonClick() {
        const { toggleOverlayByKey } = this.props;

        toggleOverlayByKey(CATEGORY_FILTER_OVERLAY_ID);
    }

    fetchLandingPage = async () => {
        const { requestLandingPage, landingpage } = this.props;

        requestLandingPage({ id: this.props?.id });
    }

    updateMeta(amlanding) {
        const { updateMeta } = this.props;

        updateMeta({ title: amlanding?.title, status_code: '200' });
    }

    updateBreadcrumbs(amlanding) {
        const { updateBreadcrumbs } = this.props;
        const { location: { pathname } } = history;

        const breadcrumbs = [
            {
                url: pathname,
                name: amlanding?.title,
            },
        ];

        updateBreadcrumbs(breadcrumbs);
    }

    getSelectedFiltersFromUrl() {
        const { location } = history;
        const selectedFiltersString = (getQueryParam('customFilters', location) || '').split(';');

        return selectedFiltersString.reduce((acc, filter) => {
            if (!filter) {
                return acc;
            }
            const [key, value] = filter.split(':');

            return { ...acc, [key]: value.split(',') };
        }, {});
    }

    getSelectedSortFromUrl() {
        const {
            sortFields: {
                options = [],
            },
        } = this.props;
        const { location } = history;

        const { landinpage } = this.state;

        /**
         * Default SORT DIRECTION is taken from (sequentially):
         * - URL param "sortDirection"
         * - CategoryPage class property "config"
         */
        const sortDirection = (getQueryParam('sortDirection', location))
            || SortDirections.ASC;


        const configSortKey = landinpage?.default_sort_by;
        const sortKey = getQueryParam('sortKey', location) || configSortKey;

        return {
            sortDirection,
            sortKey,
        };
    }

    getSelectedPriceRangeFromUrl() {
        const { location } = history;
        const min = +getQueryParam('priceMin', location);
        const max = +getQueryParam('priceMax', location);

        return { min, max };
    }

    onSortChange(sortDirection, sortKey) {
        const { location } = history;

        setQueryParams({ sortKey: sortKey.join(','), sortDirection, page: '' }, location, history);
    }

    getFilter() {
        const { id } = this.props;
        const customFilters = this.getSelectedFiltersFromUrl();
        const priceRange = this.getSelectedPriceRangeFromUrl();

        return {
            priceRange,
            customFilters,
            landing_page_id: id,
        };
    }

    onGridButtonClick() {
        BrowserDatabase.setItem(CategoryPageLayout.GRID, LAYOUT_KEY);
        this.setState({ selectedLayoutType: CategoryPageLayout.GRID });
    }

    onListButtonClick() {
        BrowserDatabase.setItem(CategoryPageLayout.LIST, LAYOUT_KEY);
        this.setState({ selectedLayoutType: CategoryPageLayout.LIST });
    }

    isContentFiltered() {
        const {
            customFilters,
            priceMin,
            priceMax,
        } = this.urlStringToObject();

        return !!(customFilters || priceMin || priceMax);
    }

    urlStringToObject() {
        const { location: { search } } = history;

        return search.substr(1).split('&').reduce((acc, part) => {
            const [key, value] = part.split('=');

            return { ...acc, [key]: value };
        }, {});
    }

    getAppliedFiltersCount() {
        const {
            selectedInfoFilter: { customFilters = {} },
        } = this.props;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return getFiltersCount(customFilters);
    }

    containerProps() {
        const { landinpage } = this.state;
        const {
            requestProductList,
            requestProductListInfo,
            filters,
            products,
            sortFields,
            id,
            isMobile,
            totalPages
        } = this.props;

        return {
            landinpage,
            requestProductList,
            requestProductListInfo,
            filters,
            products,
            selectedFilters: this.getSelectedFiltersFromUrl(),
            selectedSort: this.getSelectedSortFromUrl(),
            sortFields,
            filter: this.getFilter(),
            id,
            isMobile,
            isContentFiltered: this.isContentFiltered(),
            totalPages,
            appliedFiltersCount: this.getAppliedFiltersCount(),
        };
    }

    render() {
        return (
            <LandingPage
                {...this.containerFunctions}
                {...this.containerProps()}
            />
        )
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPageContainer);
