import {
    UPDATE_SEO_CONFIGURATION,
} from './SeoConfiguration.action';

/** @namespace Efex/MorhaneSeo/Store/SeoConfiguration/Reducer/getInitialState */
export const getInitialState = () => ({
    seoConfigurations: {},
});

/** @namespace Efex/MorhaneSeo/Store/SeoConfiguration/Reducer/SeoConfigurationReducer */
export const SeoConfigurationReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case UPDATE_SEO_CONFIGURATION:
        const { seoConfigurations: newSeoConfigurations } = action;

        return {
            ...state,
            seoConfigurations: { ...newSeoConfigurations },
        };
    default:
        return state;
    }
};

export default SeoConfigurationReducer;
