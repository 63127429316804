/** @namespace Efex/MorhaneSeo/Util/AddHrefLangTags/removePreviousHreflangs */
export const removePreviousHreflangs = () => {
    const head = document.querySelector('head');
    const elements = head.querySelectorAll('[rel="alternate"]');
    Array.from(elements).forEach((element) => head.removeChild(element));
};

/** @namespace Efex/MorhaneSeo/Util/AddHrefLangTags/addHreflangTags */
export const addHreflangTags = (hreflangs) => {
    removePreviousHreflangs();

    if (hreflangs) {
        hreflangs.forEach((data) => {
            const hreflangTag = document.createElement('link');
            hreflangTag.rel = 'alternate';
            hreflangTag.hreflang = data.locale;
            hreflangTag.href = data.url;

            if (
                !document.querySelector(
                    `[rel="alternate"][hreflang="${data.locale}"][href="${data.url}"]`
                )
            ) {
                document
                    .getElementsByTagName('head')[0]
                    .appendChild(hreflangTag);
            }
        });
    }
};

/** @namespace Efex/MorhaneSeo/Util/AddHrefLangTags/addHreflangWithPath */
export const addHreflangWithPath = (hreflangs, path) => {
    removePreviousHreflangs();

    if (hreflangs) {
        const path_arr = path.split('/'); // nl_be, path_remains
        // eslint-disable-next-line no-magic-numbers
        const localeValues = hreflangs.map((item) => item.url.split('/')[3]);
        const [code, ...remain_path] = path_arr;
        const hreflang_path = localeValues.includes(code.split('_').join('-')) ? remain_path.join('/') : path;

        hreflangs.forEach((data) => {
            const hreflangTag = document.createElement('link');
            hreflangTag.rel = 'alternate';
            hreflangTag.hreflang = data.locale;
            hreflangTag.href = data.url + hreflang_path;

            if (
                !document.querySelector(
                    `[rel="alternate"][hreflang="${data.locale}"][href="${data.url}"]`
                )
            ) {
                document
                    .getElementsByTagName('head')[0]
                    .appendChild(hreflangTag);
            }
        });
    }
};

/** @namespace Efex/MorhaneSeo/Util/AddHrefLangTags/addHreflangFor404 */
export const addHreflangFor404 = (hreflangs, path) => {
    removePreviousHreflangs();
    const path_arr = path.split('/'); // nl_be, path_remains
    // eslint-disable-next-line no-magic-numbers
    const localeValues = hreflangs.map((item) => item.url.split('/')[3]);
    const [code] = path_arr;
    const store_code = localeValues.includes(code) ? code : 'x-default';

    const hreflangTag = document.createElement('link');
    hreflangTag.rel = 'alternate';
    hreflangTag.hreflang = store_code;
    hreflangTag.href = window.location.href;

    if (
        !document.querySelector(
            `[rel="alternate"][hreflang="${store_code}"][href="${window.location.href}"]`
        )
    ) {
        document
            .getElementsByTagName('head')[0]
            .appendChild(hreflangTag);
    }
};
