import { GET_PAGE_DATA } from './LandingPage.action';

export const getInitialState = () => ({
    landingpage: {}
});

export const LandingPageReducer = (state = getInitialState(), action) => {
    switch (action.type) {
        case GET_PAGE_DATA:
            return {
                ...state,
                landingpage: action?.data
            };

        default:
            return state;
    }
};

export default LandingPageReducer;
