/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import getStore from 'Util/Store';

import { fireGeneralEvent } from '../../event/general';
import { waitForCallback } from '../../util/wait';

const addGTMGeneralEvent = async (args, callback) => {
    const [{ urlResolver = { notFound: true } },, { urlParam }] = args;
    const newUrlRewrite = urlResolver || { notFound: true };

    // vvv Wait for urlRewrite type
    await waitForCallback(() => getStore().getState().UrlRewritesReducer?.urlRewrite?.type);

    const {
        urlRewrite: oldUrlRewrite = {},
        requestedUrl = '',
    } = getStore().getState().UrlRewritesReducer;

    // check if URL changed and it's new and prevent sending duplicates event
    if (
        requestedUrl !== urlParam
        && (
            oldUrlRewrite.id !== newUrlRewrite.id
            || oldUrlRewrite.type !== newUrlRewrite.type
            || oldUrlRewrite.sku !== newUrlRewrite.sku
            || newUrlRewrite.notFound
        )
    ) {
        fireGeneralEvent(newUrlRewrite);
    }

    callback(...args);
};

export default {
    'Store/UrlRewrites/Dispatcher': {
        'member-function': {
            onSuccess: addGTMGeneralEvent,
        },
    },
};
