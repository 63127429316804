/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import getStore from 'Util/Store';

import { waitForCallback } from '../util/wait';
import { getProductClickPageData } from './page';
import { getItemListId, getProductListEntryData } from './product';

export const DL_VAL_CATEGORY_LIMIT = 5;

/** @namespace Scandiweb/Gtm/Data/List/getProductImpressionsData */
export const getProductImpressionsData = async (
    products,
    positions,
    forcedList,
    urlPathname,
    urlRewriteData,
    category
) => {
    // vvv Wait for currency code
    await waitForCallback(() => getStore().getState().ConfigReducer?.currencyData?.current_currency_code);
    const currencyCode = getStore().getState().ConfigReducer?.currencyData?.current_currency_code;

    const { pageType: list } = await getProductClickPageData(
        urlPathname,
        urlRewriteData,
        category
    );
    // ^^^ Reuse page data as list information

    const item_list_name = forcedList || list;

    const batchedImpressions = await products.map(async (product, index) => {
        // vvv To remove variant from productData
        // eslint-disable-next-line no-unused-vars

        const { variant, ...productData } = await getProductListEntryData(product, positions[index], item_list_name);
        const listId = await getItemListId(item_list_name);

        return {
            ...productData,
            item_list_name: forcedList || list,
            ...(currencyCode ? { currency: currencyCode } : {}),
            ...listId,
        };
    });

    const listId = await getItemListId(item_list_name);

    return {
        item_list_name: forcedList || list,
        ...listId,
        ...(currencyCode ? { currency: currencyCode } : {}),
        items: await Promise.all(batchedImpressions),
    };
};

/** @namespace Scandiweb/Gtm/Data/List/getCategoriesLevels */
export const getCategoriesLevels = async (categories) => {
    const levels = categories.breadcrumbs.slice(0, DL_VAL_CATEGORY_LIMIT).map(({ category_name }) => category_name);
    const levelsObj = {};
    levels.forEach((category, index) => {
        if (index === 0) {
            levelsObj.item_brand = category;

            return;
        }

        if (index === 1) {
            levelsObj.item_category = category;

            return;
        }

        levelsObj[`item_category${index}`] = category;
    });

    return levelsObj;
};

/** @namespace Scandiweb/Gtm/Data/List/getProductClickData */
export const getProductClickData = async (
    product,
    position,
    forcedList,
    urlPathname,
    urlRewriteData,
    category
) => {
    const { pageType: list } = await getProductClickPageData(
        urlPathname,
        urlRewriteData,
        category
    );
    const item_list_name = forcedList || list;

    const { variant, ...productData } = await getProductListEntryData(product, position, item_list_name);
    const currencyCode = getStore().getState().ConfigReducer?.currencyData?.current_currency_code;
    const listId = await getItemListId(item_list_name);

    return {
        item_list_name,
        ...listId,
        currency: currencyCode,
        items: [productData],
    };
};
