import { Field } from 'Util/Query';

/** @namespace Efex/MorhaneSeo/Query/HreflangUrls/Query */
export class HreflangUrlsQuery {
    getHreflangUrls(id, action) {
        return new Field('hreflangUrls')
            .addArgument('id', 'Int!', id)
            .addArgument('action', 'String!', action)
            .addFieldList(['locale', 'url']);
    }
}

export default new HreflangUrlsQuery();
