/* eslint-disable react/no-unknown-property */
import { PureComponent } from 'react';

import './FieldImageSelect.style';

/** @namespace Efex/MorhanePdpVariants/Component/FieldImageSelect/Component */
export class FieldImageSelectComponent extends PureComponent {
    render() {
        const {
            option,
            isExpanded,
            handleSelectListOptionClick,
        } = this.props;

        const {
            id,
            label,
            subLabel,
            isPlaceholder = false,
            isHovered,
            isAvailable = true,
            image = '',
        } = option;

        return (
            <li
              block="FieldSelect"
              elem="Option"
              mods={ {
                  isDisabled: !isAvailable,
                  isExpanded,
                  isPlaceholder,
                  isHovered,
              } }
              key={ id }
              id={ `o${id}` }
              role="menuitem"
              onMouseDown={ () => handleSelectListOptionClick(option) }
              onTouchStart={ () => handleSelectListOptionClick(option) }
              onKeyPress={ () => handleSelectListOptionClick(option) }
              tabIndex={ isExpanded ? 0 : -1 }
            >
                { image !== '' ? <img src={image} alt={label} /> : null }
                { label }
                { subLabel && (
                    <strong>
                        { ` ${subLabel}` }
                    </strong>
                ) }
            </li>
        );
    }
}

export default FieldImageSelectComponent;
