/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import Loader from 'Component/Loader';
import { PageProps, ProductListComponentProps } from 'Component/ProductList/ProductList.type';
import {
    ProductListComponent as SourceProductListComponent,
} from 'SourceComponent/ProductList/ProductList.component';
import { ReactElement } from 'Type/Common.type';

import './ProductList.override.style';

export * from 'SourceComponent/ProductList/ProductList.component';

/**
 * List of category products
 * @class ProductList
 * @namespace Celtik/Component/ProductList/Component */
export class ProductListComponent extends SourceProductListComponent {
    state: { isNextPageLoading: boolean } = {
        isNextPageLoading: false,
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidUpdate(prevProps: ProductListComponentProps): void {
        const { pages } = this.props;
        const { pages: prevPages } = prevProps;

        if (Object.keys(pages).length !== Object.keys(prevPages).length) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ isNextPageLoading: false });
        }

        this.observePageChange();
    }

    renderLoadButton(): ReactElement {
        const {
            isShowLoading,
            loadPrevPage,
        } = this.props;

        if (!isShowLoading) {
            return null;
        }

        return (
            <div
              block="ProductList"
              elem="LoadButton"
              role="button"
              tabIndex={ 0 }
              onKeyUp={ loadPrevPage }
              onClick={ loadPrevPage }
            >
                { __('Load previous') }
            </div>
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    loadMoreProducts: () => void = (): void => {
        const { loadPage } = this.props;

        this.setState({ isNextPageLoading: true });
        loadPage(true);
    };

    renderLoadMoreButton(): ReactElement {
        const {
            totalPages,
            currentPage,
            isLoading,
        } = this.props;
        const { isNextPageLoading } = this.state;

        if (totalPages === currentPage || isLoading) {
            return null;
        }

        return (
            <div block="ProductList" elem="LoadMoreButtonWrapper">
                <Loader isLoading={ isNextPageLoading } />
                <button
                  block="Button"
                  mods={ { isHollow: true } }
                  mix={ { block: 'ProductList', elem: 'LoadMoreButton' } }
                  onClick={ this.loadMoreProducts }
                  disabled={ isNextPageLoading }
                >
                    { __('Load more') }
                </button>
            </div>
        );
    }

    renderPageInfo(): ReactElement {
        const { currentPage, totalPages } = this.props;

        return (
            <div block="ProductList" elem="PageInfo">
                { __('%s of %s pages viewed', currentPage, totalPages) }
            </div>
        );
    }

    renderPagination(): ReactElement {
        const { isWidget } = this.props;

        if (isWidget) {
            return null;
        }

        return (
            <div block="ProductList" elem="PageInfoAndLoadMore">
                { this.renderPageInfo() }
                { this.renderLoadMoreButton() }
            </div>
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    _processProps(props: Partial<PageProps>): Partial<PageProps> {
        return props;
    }
}

export default ProductListComponent;
