/* eslint-disable react/no-unknown-property */
import { PureComponent } from 'react';
import Link from 'Component/Link';

import './VariantColorSwatchOption.style';

/** @namespace Efex/MorhanePdpVariants/Component/VariantColorSwatchOption/Component */
export class VariantColorSwatchOptionComponent extends PureComponent {
    render() {
        const {
            key,
            attribute,
            isSelected,
            isAvailable,
            image,
            onClick,
        } = this.props;

        return (
            <a
              key={ key }
              block="VariantColorSwatchOption"
              elem="Select"
              mix={ { block: 'VariantColorSwatchOption', mods: { isSelected, isAvailable } } }
              onClick={ () =>  isSelected ? null : onClick(attribute) }
            >
                <img src={ image } alt={ key } />
            </a>
        );
    }
}

export default VariantColorSwatchOptionComponent;
