/* https://github.com/Ylmzef/morhane-frontend/pull/11/commits */
/* eslint-disable @typescript-eslint/naming-convention */ 
/* eslint-disable no-param-reassign */
import { Query } from '@tilework/opus';
import { Dispatch } from 'redux';

import { ProductListOptions } from 'Query/ProductList.type';
import { NetworkError } from 'Type/Common.type';
import { prepareQuery } from 'Util/Query';
import { executeGet, listenForBroadCast } from 'Util/Request/Request';

export const handleData = async (
    args: Array<Dispatch | Partial<ProductListOptions>>,
    callback: () => void,
    instance: any,
): Promise<void> => {
    const [dispatch, options] = args;
    const { name, cacheTTL } = instance;
    const sAnchor = 'prlist_d_anchor';
    // @ts-ignore
    window.sAnchor = sAnchor;

    const rawQueries = instance.prepareRequest(options, dispatch);

    if (!rawQueries) {
        return;
    }

    if (instance.controller) {
        instance.controller.abort();
    }

    const queries = rawQueries instanceof Query ? [rawQueries] : rawQueries;

    instance.controller = new AbortController();
    const { signal } = instance.controller;

    try {
        instance.updatedByBroadcast = false;

        listenForBroadCast(name).then(
            (data) => {
                console.log('BROADCAST', data, options);
                instance.updatedByBroadcast = true;
                instance.onUpdate(data, dispatch, options);
            },
        );
        console.log('will execute GET');
        instance.promise = await executeGet(prepareQuery(queries), name, cacheTTL, signal);
        console.log('GET executed');

        if (instance.promise && !instance.updatedByBroadcast) {
            console.log('update from GET', instance.promise, options);
            instance.onSuccess(instance.promise, dispatch, options);
        }
    } catch (err) {
        if (!(err as NetworkError).message.includes('abort')) {
            if (!(err as NetworkError).message.includes('abort')) {
                instance.onError(err, dispatch);
            }
        }
    }
};

export default {
    'Store/ProductList/Dispatcher': {
        'member-function': {
            handleData,
        },
    },
};
