/* eslint-disable react/no-unknown-property */
import { PureComponent } from 'react';

import './VariantSelectOption.style';

/** @namespace Efex/MorhanePdpVariants/Component/VariantSelectOption/Component */
export class VariantSelectOptionComponent extends PureComponent {
    render() {
        const {
            onChange,
            label,
            value,
            key,
            isSelected,
            isAvailable,
        } = this.props;

        return (
            <button
              key={ key }
              block="VariantSelectOption"
              elem="Select"
              mix={ { block: 'VariantSelectOption', mods: { isSelected, isAvailable } } }
              onClick={ () => isSelected ? null : onChange(value) }
            >
                { label }
            </button>
        );
    }
}

export default VariantSelectOptionComponent;
