import { Field } from 'Util/Query';

/** @namespace Efex/MorhaneSeo/Query/SeoConfiguration/Query */
export class SeoConfigurationQuery {
    seoConfigurations() {
        return new Field('seoInfo')
            .addField(this._getMetaRobotsConfig())
            .addField(this._getCanonicalConfig())
            .addField(this._getMicroDataConfig())
            .addField(this._getSiteMapConfig())
            .addField(this._getSiteVerificationConfig())
            .addField(this._getTwitterCardConfig())
            .addField(this._getHreflangConfig());
    }

    _getMetaRobotsConfig() {
        return new Field('metaRobots')
            .addFieldList([
                'isNoIndexActive',
                'defaultRobots',
            ]);
    }

    _getCanonicalConfig() {
        return new Field('canonical')
            .addFieldList([
                'isRelPagination',
                'isCmsActive',
                'isSimpleProductSitemap',
                'isStorePreferenceEnable',
            ]);
    }

    _getMicroDataConfig() {
        return new Field('microData')
            .addFieldList([
                'brand',
                'isActive',
            ]);
    }

    _getSiteMapConfig() {
        return new Field('siteMap')
            .addFieldList([
                'isCategoryActive',
                'categoryUrlKey',
                'maxDepth',
            ]);
    }

    _getSiteVerificationConfig() {
        return new Field('siteVerification')
            .addFieldList([
                'isActive',
                'googleVerificationCode',
                'bingVerificationCode',
                'pintrestVerificationCode',
                'yandexVerificationCode',
            ]);
    }

    _getTwitterCardConfig() {
        return new Field('twitterCard')
            .addFieldList([
                'isActive',
                'defaultCardType',
                'defaultSiteUsername',
                'defaultCreator',
            ]);
    }

    _getHreflangConfig() {
        return new Field('hreflang')
            .addFieldList([
                'isActive',
                'useSameWebsiteOnly',
                'localeCode',
            ]);
    }

    _getHreflangUrls() {
        return new Field('hreflangUrls')
            .addFieldList([
                'locale',
                'url',
            ]);
    }
}

export default new SeoConfigurationQuery();
