/* eslint-disable arrow-body-style */
/* eslint-disable comma-dangle */
/* eslint-disable quote-props */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-arrow-functions-in-class */

export class MetaComponentPlugin {
    renderCanonical = (args, callback, instance) => {
        const { canonical_url } = instance.props;
        const { href } = window.location;
        const url = new URL(href);
        const { searchParams } = url;
        const pageNumber = searchParams.get('page');

        if (!canonical_url) {
            return null;
        }

        if (pageNumber !== null && !canonical_url.includes('?')) {
            return (
                <link rel="canonical" href={ `${canonical_url}?page=${pageNumber}` } />
            );
        }

        return (
            <link rel="canonical" href={ canonical_url } />
        );
    };
}

const {
    renderCanonical,
} = new MetaComponentPlugin();

export default {
    'Component/Meta/Component': {
        'member-function': {
            renderCanonical,
        },
    },
};
