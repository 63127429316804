/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    getFilterDetails, getFilterDifferences, getFiltersCategoryName, getFiltersCount,
} from '../data/filter';
import {
    GTM_EVENT_KEY_ALL_FILTERS_REMOVED,
    GTM_EVENT_KEY_FILTER_APPLIED,
    GTM_EVENT_KEY_FILTER_REMOVED,
} from '../util/events';
import { pushToDataLayer } from '../util/push';
import { debounceCallback } from '../util/wait';

export const DEBOUNCE_INTERVAL_MS = 1000;

/** @namespace Scandiweb/Gtm/Event/Filter/fireProductFilterAppliedEvent */
export const fireFilterAppliedEvent = debounceCallback(async (addedFilters, filtersDetails, resultsCount) => {
    const filterKey = Object.keys(addedFilters[0])[0];
    const filterValue = addedFilters[0][filterKey][0];

    pushToDataLayer({
        event: GTM_EVENT_KEY_FILTER_APPLIED,
        ...getFilterDetails(filtersDetails, filterKey, filterValue),
        results_returned: resultsCount,
    });
}, DEBOUNCE_INTERVAL_MS);

/** @namespace Scandiweb/Gtm/Event/Filter/fireProductFilterAppliedEvent */
export const fireFilterRemovedEvent = debounceCallback(async (removedFilters, filtersDetails) => {
    const filterKey = Object.keys(removedFilters[0])[0];
    const filterValue = removedFilters[0][filterKey][0];

    pushToDataLayer({
        event: GTM_EVENT_KEY_FILTER_REMOVED,
        ...getFilterDetails(filtersDetails, filterKey, filterValue),
    });
});

export const fireAllFiltersRemovedEvent = debounceCallback(async (removedFilters, filtersDetails) => {
    const filterKeys = removedFilters.map((filter) => Object.keys(filter)[0]);
    // ^^^ in each element of array there is an object with only one property so [0] is for that

    pushToDataLayer({
        event: GTM_EVENT_KEY_ALL_FILTERS_REMOVED,
        filterCategories: getFiltersCategoryName(filtersDetails, filterKeys).join(','),
    });
});

/** @namespace Scandiweb/Gtm/Event/Filter/filterCounts */
export const filterCounts = (selectedFilters, allFilters) => {
    const selectedFilterName = Object.keys(selectedFilters[0])[0];
    const selectedFilterValue = selectedFilters[0][selectedFilterName][0];

    const selectedFilter = allFilters.find((filter) => filter.request_var === selectedFilterName);
    const selectedFilterItem = selectedFilter?.filter_items.find(
        (item) => item.value_string === selectedFilterValue
    );

    const selectedFilterCount = selectedFilterItem?.count || 0;

    return selectedFilterCount;
};

/** @namespace Scandiweb/Gtm/Event/Filter/handleProductFilterEvents */
export const handleProductFilterEvents = (oldCustomFilters, customFilters, filtersDetails) => {
    const lastPLPUrl = sessionStorage.getItem('lastPLPUrl');
    const [removedFilters, addedFilters] = getFilterDifferences(oldCustomFilters, customFilters);
    const currentLocation = location.origin + location.pathname;
    const addedFiltersCount = getFiltersCount(addedFilters);
    const removedFiltersCount = getFiltersCount(removedFilters);
    const pushRemovedFiltersToDataLayer = lastPLPUrl && lastPLPUrl === currentLocation;
    // ^^^ if it's undefined means it's first time here and we don't need to dispatch RemovedFilters
    // ^^^ if they're equal it means we might removed/added filter(s) if they're not we don't need to dispatch removedFilters
    // ^^^ if we don't have any item in it, don't push it

    if (!addedFilters) {
        return;
    }

    if (pushRemovedFiltersToDataLayer) {
        // vvv If it's < 1 it means nothing has been added. If it's 1 means we removed filter by clicking on filters
        if (removedFiltersCount === 1) {
            fireFilterRemovedEvent(removedFilters, filtersDetails);
        }

        // vvv If it's < 1 it means nothing has been added. If it's >1 means we removed filters by clicking on 'reset all' button
        if (removedFiltersCount > 1) {
            fireAllFiltersRemovedEvent(removedFilters, filtersDetails);
        }
    }

    // vvv If it's < 1 it means nothing has been added. If it's >1 means we added filter through URL not by clicking on filters
    if (addedFiltersCount === 1) {
        const resultsCount = filterCounts(addedFilters, filtersDetails);

        if (resultsCount) {
            fireFilterAppliedEvent(addedFilters, filtersDetails, resultsCount);
        }
    }

    sessionStorage.setItem('lastPLPUrl', currentLocation);
};
