/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { updateProductListInfo } from 'Store/ProductListInfo/ProductListInfo.action';

import { getCustomerData } from '../../data/customer';

const aroundOnSuccess = async (functionArgs) => {
    const [{ products }, dispatch, options] = functionArgs;
    const {
        args: {
            filter,
        },
    } = options;
    const { customerId } = await getCustomerData();

    dispatch(
        {
            ...updateProductListInfo(products, filter),
            customerId,
        }
    );
};

export default {
    'Store/ProductListInfo/Dispatcher': {
        'member-function': {
            onSuccess: aroundOnSuccess,
        },
    },
};
