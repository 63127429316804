/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { ProductListEntryTracker } from '../../component/ProductListEntryTracker';

const addProductCardImpressionObserver = (args, callback, instance) => (
    <ProductListEntryTracker product={ instance.props.product }>
        { callback(...args) }
    </ProductListEntryTracker>
);

export default {
    'Component/ProductCard/Component': {
        'member-function': {
            render: addProductCardImpressionObserver,
        },
    },
};
