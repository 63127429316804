// // TODO rename
export const GET_PAGE_DATA = 'GET_PAGE_DATA';

// // TODO rename
// export const action = (payload) => ({
//     type: GET_PAGE_DATA,
//     payload,
// });

export const appendPage = (data) => ({
    type: "GET_PAGE_DATA",
    data,
});