/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Page } from 'Component/Header/Header.config';
import {
    ProductListContainerProps,
} from 'Component/ProductList/ProductList.type';
import {
    mapDispatchToProps,
    mapStateToProps,
    ProductListContainer as SourceProductListContainer,
} from 'SourceComponent/ProductList/ProductList.container';
import { scrollToTop } from 'Util/Browser';
import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';

export * from 'SourceComponent/ProductList/ProductList.container';

/** @namespace Celtik/Component/ProductList/Container */
export class ProductListContainer extends SourceProductListContainer {
    componentDidMount(): void {
        const {
            pages, isPreventRequest,
        } = this.props;
        const { pagesCount } = this.state;
        const pagesLength = Object.keys(pages).length;

        if (pagesCount !== pagesLength) {
            this.setState({ pagesCount: pagesLength });
        }

        // Is true when category is changed. This check prevents making new requests when navigating back to PLP from PDP
        if (!isPreventRequest && window.lastProductListHref !== location.pathname + location.search) {
            this.requestPage(this._getPageFromUrl());
        }
    }

    componentDidUpdate(prevProps: ProductListContainerProps): void {
        const {
            sort,
            search,
            filter,
            pages,
        } = this.props;

        const {
            sort: prevSort,
            search: prevSearch,
            filter: prevFilter,
        } = prevProps;

        const { pagesCount } = this.state;
        const pagesLength = Object.keys(pages).length;

        if (pagesCount !== pagesLength) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ pagesCount: pagesLength });
        }

        if (
            JSON.stringify(filter) !== JSON.stringify(prevFilter)
            || JSON.stringify(sort) !== JSON.stringify(prevSort)
        ) {
            window.isPrefetchValueUsed = false;
        }

        if (search !== prevSearch
            || JSON.stringify(sort) !== JSON.stringify(prevSort)
            || JSON.stringify(filter) !== JSON.stringify(prevFilter)
        ) {
            this.requestPage(this._getPageFromUrl());
        }
    }

    componentWillUnmount(): void {
        const { location: { pathname, search } } = this.props;
        super.componentWillUnmount();
        window.lastProductListHref = pathname + search;
    }

    requestPage(currentPage = 1, isNext = false): void {
        const {
            sort,
            search,
            filter,
            pageSize,
            requestProductList,
            requestProductListInfo,
            noAttributes,
            noVariants,
            isWidget,
            location: { pathname },
        } = this.props;
        const { isPrefetchValueUsed } = window;

        const isSearch = pathname.includes(Page.SEARCH);
        const isPrefetched = isPrefetchValueUsed && !isWidget && !isSearch && !isNext;

        /**
         * In case the wrong category was passed down to the product list,
         * prevent it from being requested.
         */
        if (filter.categoryIds === -1) {
            return;
        }

        /**
         * Do not request page if there are no filters
         */
        if (!search && !this.isEmptyFilter()) {
            return;
        }

        // TODO: product list requests filters alongside the page
        // TODO: sometimes product list is requested more then once
        // TODO: the product list should not request itself, when coming from PDP

        const options = {
            isNext,
            noAttributes,
            noVariants,
            args: {
                sort: sort ?? undefined,
                filter,
                search,
                pageSize,
                currentPage,
            },
        };

        const infoOptions = {
            args: {
                filter,
                search,
            },
        };

        if (!isPrefetched) {
            requestProductList(options);
        }

        if (!isWidget) {
            waitForPriorityLoad().then(
            /** @namespace Celtik/Component/ProductList/Container/ProductListContainer/requestPage/waitForPriorityLoad/then/requestProductListInfo */
                () => requestProductListInfo(infoOptions),
            );

            if (!isNext) {
                scrollToTop();
            }
        }
    }

    _getIsInfiniteLoaderEnabled(): boolean {
        return false;
    }

    loadPage(next = true): void {
        const { pagesCount } = this.state;
        const { isPageLoading } = this.props;

        const {
            minPage,
            maxPage,
            totalPages,
            loadedPagesCount,
        } = this._getPagesBounds();

        const isUpdatable = totalPages > 0 && pagesCount === loadedPagesCount;
        const shouldUpdateList = next ? maxPage < totalPages : minPage > 1;

        if (isUpdatable && shouldUpdateList && !isPageLoading) {
            this.setState({ pagesCount: pagesCount + 1 });
            this.requestPage(next ? maxPage + 1 : minPage - 1, true);
        }
    }

    updatePage(pageNumber: number): void {
        const { location: { search }, history } = this.props;

        const pageRegex = /(.*)page=[0-9]+/;
        const parameters = search.split('&').reduce((acc: string[], parameter): string[] => {
            if (['', '?'].includes(parameter) || pageRegex.test(parameter)) {
                return acc;
            }

            return [...acc, parameter];
        }, []);
        const newPageQuery = (pageNumber === 1 ? parameters : [...parameters, `page=${pageNumber}`]).join('&');

        history.replace({ search: newPageQuery });
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(
        ProductListContainer as unknown as React.ComponentType<
        RouteComponentProps & ProductListContainerProps
        >,
    ),
);
