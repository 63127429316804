import { getStaticReducers as sourceGetStaticReducers } from 'SourceStore/index';

import SeoConfigurationReducer
    from '../../packages/@efex/morhane-seo/src/store/SeoConfiguration/SeoConfiguration.reducer.js';
import LandingPageReducer from '../../packages/@efex/morhane-xlanding/src/plugin/store/LandingPage/LandingPage.reducer';

/** @namespace Celtik/Store/Index/getStaticReducers */
export const getStaticReducers = () => ({
    ...sourceGetStaticReducers(),
    LandingPageReducer,
    SeoConfigurationReducer,
});

export default function injectStaticReducers(store) {
    // eslint-disable-next-line no-param-reassign
    store.asyncReducers = [];

    // Inject all the static reducers into the store
    Object.entries(getStaticReducers()).forEach(
        ([name, reducer]) => {
            // eslint-disable-next-line no-param-reassign
            store.asyncReducers[name] = reducer;
            store.injectReducer(name, reducer);
        }
    );

    return store;
}
