import { ProductType } from 'Component/Product/Product.config';
import TextPlaceholder from 'Component/TextPlaceholder';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import {
    ProductCardComponent as SourceProductCardComponent,
} from 'SourceComponent/ProductCard/ProductCard.component';

/** @namespace Celtik/Component/ProductCard/Component */
export class ProductCardComponent extends SourceProductCardComponent {
    renderMainDetails() {
        const { product: { name } } = this.props;

        return (
            <p
              block="ProductCard"
              elem="Name"
              mods={ { isLoaded: !!name } }
            >
                <TextPlaceholder content={ name } length={ TextPlaceHolderLength.LONG } />
            </p>
        );
    }

    renderPrice() {
        const {
            getActiveProduct,
            product: {
                type_id: baseType,
            } = {},
            inStock,
        } = this.props;

        const {
            price_range: priceRange,
            type_id: typeId,
        } = getActiveProduct();

        if (!priceRange) {
            return this.renderTextPlaceholder();
        }

        if (baseType === ProductType.CONFIGURABLE || !inStock) {
            return null;
        }

        // If product is not a variant.
        const notConfigured = baseType !== ProductType.CONFIGURABLE || typeId === baseType;

        return super.renderPrice(notConfigured);
    }
}

export default ProductCardComponent;
