/* eslint-disable arrow-body-style */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-arrow-functions-in-class */
import { fetchQuery } from '@scandipwa/scandipwa/src/util/Request/Query';

import BreadcrumbsDispatcher from 'Store/Breadcrumbs/Breadcrumbs.dispatcher';

import { addHreflangFor404 } from '../../util/AddHreflangTags.js';
import HreflangUrlsQuery from '../query/HreflangUrls.query';

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        seoConfigurations: state.SeoConfigurationReducer.seoConfigurations,
        configurations: state.ConfigReducer,
        urlRewrite: state.UrlRewritesReducer.urlRewrite,
    };
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        updateBreadcrumbs: (breadcrumbs) => BreadcrumbsDispatcher.update(breadcrumbs, dispatch),
    };
};

export class NoMatchContainerPlugin {
    constructExtended = (args, callback, instance) => {
        callback(...args);
        // eslint-disable-next-line no-param-reassign
        instance.state = {
            ...instance.state,
            hreflangUrls: [],
        };
    };

    componentDidMount = (args, callback, _instance) => {
        const {
            seoConfigurations,
            updateBreadcrumbs,
            configurations: {
                code = '',
            } = {},
            urlRewrite: { type }
        } = _instance.props;
        const id = 1;
        const action = 'cms_index_index';
        const isActive = seoConfigurations?.hreflang?.isActive ?? false;
        const url_code = window.location.pathname.split('/')[1];
        const url_path = code === url_code ? window.location.pathname : `/${code}${window.location.pathname}`;
        // eslint-disable-next-line no-unused-expressions
        if (type !== 'AMASTY_XLANDING_PAGE') {
            isActive && fetchQuery(HreflangUrlsQuery.getHreflangUrls(id, action))
                .then(
                    (response) => {
                        addHreflangFor404(response?.hreflangUrls || [], window.location.pathname.slice(1));
                    }
                );
        }

        updateBreadcrumbs([{
            name: 'Not Found',
            url: url_path,
        }]);
        callback(...args);
    };
}

const {
    componentDidMount,
    constructExtended,
} = new NoMatchContainerPlugin();

export default {
    'Route/NoMatch/Container': {
        'member-function': {
            componentDidMount,
            __construct: constructExtended,
        },
    },
    'Route/NoMatch/Container/mapStateToProps': {
        function: [
            {
                position: 50,
                implementation: mapStateToProps,
            },
        ],
    },
    'Route/NoMatch/Container/mapDispatchToProps': {
        function: mapDispatchToProps,
    },
};
