import { GQLProductStockStatus } from 'Type/Graphql.type';

// /** @namespace Efex/MorhanePdpVariants/Plugin/Compoennt/ProductConfigurableAttributes/ProductConfigurableAttributesContainer/containerProps */
export const containerProps = (args, callback, instance) => {
    const { variants, productId } = instance.props;

    return {
        ...callback(...args),
        variants,
        productId,
    };
};

/** @namespace Efex/MorhanePdpVariants/Plugin/Route/ProductConfigurableAttributes/ProductConfigurableAttributesContainer/getIsConfigurableAttributeAvailable */
export const getIsConfigurableAttributeAvailable = (args, callback, instance) => {
    const { attribute_code = '', attribute_value = '' } = args[0];

    const { parameters, variants = [] } = instance.props;

    // skip out of stock check, if variants data has not been provided
    if (!variants.length) {
        return true;
    }

    const isAttributeSelected = Object.hasOwnProperty.call(parameters, attribute_code);

    const parameterPairs = Object.entries(parameters);

    const selectedAttributes = isAttributeSelected
        // Need to exclude itself, otherwise different attribute_values of the same attribute_code will always be disabled
        ? parameterPairs.filter(([key]) => key !== attribute_code)
        : parameterPairs;

    return variants
        .some(({ stock_status, attributes }) => {
            const { attribute_value: foundValue } = attributes[ attribute_code ] || {};

            return (
                stock_status === GQLProductStockStatus.IN_STOCK
                // Variant must have currently checked attribute_code and attribute_value
                && foundValue === attribute_value
                // Variant must have all currently selected attributes
                && selectedAttributes.every(([key, value]) => attributes[ key ].attribute_value === value)
            );
        });
};

/** @namespace Efex/MorhanePdpVariants/Plugin/Route/ProductConfigurableAttributes/ProductConfigurableAttributesContainer/isSelected */
export const isSelected = (args, callback, instance) => {
    const { attribute_code = '', attribute_value = '' } = args[0];

    const { parameters = {} } = instance.props;
    const parameter = parameters[ attribute_code ];

    if (parameter === undefined) {
        return false;
    }

    if (typeof parameter === 'object' && parameter.length !== undefined) {
        return parameter.includes(attribute_value);
    }

    return parameter === attribute_value;
};

export default {
    'Component/ProductConfigurableAttributes/Container': {
        'member-function': {
            containerProps,
            getIsConfigurableAttributeAvailable,
            isSelected,
        }
    },
};
