import { cloneElement } from 'react';
import Field from 'Component/Field';
import ProductConfigurableAttributeDropdown from 'Component/ProductConfigurableAttributeDropdown';
import { FieldType } from 'Component/Field/Field.config';
import { getVariantIndex } from 'Util/Product';
import { GQLProductStockStatus } from 'Type/Graphql.type';
import media, { PRODUCT_MEDIA } from 'Util/Media/Media';

import VariantColorSwatchOption from '../../../component/VariantColorSwatchOption';

const getConfigurableAttributeData = ({ attribute_code = '', attribute_value = '' }, instance) => {
    const { variants } = instance.props;

    // skip out of stock check, if variants data has not been provided
    if (!variants.length) {
        return { status: false };
    }

    const filteredVariant = variants.find(({ attributes }) => {
        const { attribute_value: foundValue } = attributes[ attribute_code ] || {};
        return foundValue === attribute_value;
    });

    if (filteredVariant) {
        const { id, url, canonical_url, stock_status, thumbnail, attributes } = filteredVariant;

        return {
            id,
            status: true,
            isAvailable: stock_status === GQLProductStockStatus.IN_STOCK,
            image: thumbnail?.url || media(attributes?.thumbnail?.attribute_value, PRODUCT_MEDIA) || '',
            canonical_url: canonical_url,
            url,
        }
    }

    return { status: false };
}

const renderColorSwatch = (attribute, instance) => {
    const {
        handleOptionClick,
        isSelected,
        getIsConfigurableAttributeAvailable,
    } = instance.props;

    const { attribute_value  } = attribute;

    const configurableAttr = getConfigurableAttributeData(attribute, instance);
    const { image = '' } = configurableAttr;

    return (
        <VariantColorSwatchOption
          key={ attribute_value }
          isSelected={ isSelected(attribute) }
          attribute={ attribute }
          isAvailable={ getIsConfigurableAttributeAvailable(attribute) }
          image={ image }
          onClick={ handleOptionClick }
        />
    );
}

/** @namespace Efex/MorhanePdpVariants/Plugin/Component/ProductConfigurableAttributes/renderSwatch */
export const renderDropdown = (args, callback, instance) => {
    const originalElement = callback.apply(instance, args);

    const {
        updateConfigurableVariant,
        parameters,
        handleShakeAnimationEnd,
        mix,
        inStock,
        getIsConfigurableAttributeAvailable,
    } = instance.props;

    const option = args[0];
    const isUnselected = args[1];

    const { block } = mix;

    const getIsConfigurableAttributeData = ({ attribute_code, attribute_value }) => {
        const { status } = getConfigurableAttributeData({
            attribute_code,
            attribute_value,
        }, instance);

        const isAvailable = getIsConfigurableAttributeAvailable({attribute_code, attribute_value});

        return { status, isAvailable };
    }

    if (block === 'ProductActions') {
        return (
            <ProductConfigurableAttributeDropdown
              handleShakeAnimationEnd={ handleShakeAnimationEnd }
              isUnselected={ isUnselected }
              option={ option }
              updateConfigurableVariant={ updateConfigurableVariant }
              getIsConfigurableAttributeAvailable={ getIsConfigurableAttributeData }
              parameters={ parameters }
              inStock={ inStock }
            />
        );
    }

    return cloneElement(originalElement);
};

/** @namespace Efex/MorhanePdpVariants/Plugin/Component/ProductConfigurableAttributes/renderSwatch */
export const renderSwatch = (args, callback, instance) => {
    const originalElement = callback.apply(instance, args);

    const {
        handleShakeAnimationEnd,
        parameters,
        mix,
        updateConfigurableVariant,
    } = instance.props;

    const option = args[0];
    const { attribute_values = [], attribute_code, attribute_options, attribute_label } = option;
    const { block } = mix;

    if (attribute_code === 'color' && block === 'ProductActions') {
        if (attribute_values.length <= 6) {
            return attribute_values.map((attribute_value) => (
                renderColorSwatch({ ...option, attribute_value }, instance)
            ));
        } else {
            const selectOptions = Object.values(attribute_options)
                .reduce((acc, option) => {
                    const { value } = option;

                    const { isAvailable, image } = getConfigurableAttributeData({
                        attribute_code,
                        attribute_value: value,
                    }, instance);

                    return [...acc, {
                        ...option,
                        id: value,
                        image,
                        isAvailable,
                    }];
                }, []);

            const selectValue = parameters[attribute_code]?.toString();

            const onChange = (value) => {
                if (updateConfigurableVariant && value !== selectValue) {
                    updateConfigurableVariant(attribute_code, value);
                }
            };

            return (
                <Field
                  type={ FieldType.SELECT }
                  attr={ {
                      id: attribute_code,
                      name: attribute_code,
                      value: selectValue,
                      defaultValue: selectValue,
                      selectPlaceholder: __('Choose %s...', attribute_label.toLowerCase()),
                      onAnimationEnd: handleShakeAnimationEnd,
                  } }
                  events={ {
                      onChange,
                  } }
                  mix={ { block: 'ProductConfigurableAttributeDropdown' } }
                  options={ selectOptions }
                  changeValueOnDoubleClick={ false }
                />
            );
        }
    }

    return cloneElement(originalElement);
};

/** @namespace Efex/MorhanePdpVariants/Plugin/Component/ProductConfigurableAttributes/renderConfigurableAttributes */
export const renderConfigurableAttributes = (args, callback, instance) => {
    const originalElement = callback.apply(instance, args);

    const {
        configurable_options,
        isExpandable,
        handleShakeAnimationEnd,
        addToCartTriggeredWithError,
        parameters,
        mix,
    } = instance.props;

    const { block } = mix;

    if (block === 'ProductActions') {
        return Object.values(configurable_options).map((option) => {
            const {
                attribute_code = '',
                attribute_label,
                attribute_options = {},
                attribute_id,
            } = option;
            const isUnselected = addToCartTriggeredWithError ? !parameters[attribute_code] : false;
            const [{ swatch_data }] = Object.values(attribute_options).length
                ? Object.values(attribute_options)
                : [{ swatch_data: undefined }];
            const isSwatch = !!swatch_data;

            // render content without heading and subheading
            if (!isExpandable) {
                return isSwatch ? instance.renderSwatch(option) : instance.renderDropdown(option);
            }

            const selectedOption = parameters[attribute_code]?.toString();
            const selectedOptionLabel = selectedOption ? attribute_options[selectedOption]?.label : '';

            return (
                <div key={ attribute_id }>
                    <p
                    block="ProductConfigurableAttributes"
                    elem="Title"
                    mods={ { isUnselected } }
                    onAnimationEnd={ handleShakeAnimationEnd }
                    >
                        { attribute_label }
                        { isSwatch && (
                            <span block="ProductConfigurableAttributes" elem="SelectedOptionLabel">
                                { selectedOptionLabel }
                            </span>
                        ) }
                    </p>
                    { isSwatch ? instance.renderSwatch(option, isUnselected) : instance.renderDropdown(option, isUnselected) }
                </div>
            );
        });
    }

    return cloneElement(originalElement);
};

export default {
    'Component/ProductConfigurableAttributes/Component': {
        'member-function': {
            renderSwatch,
            renderDropdown,
            renderConfigurableAttributes,
        },
    },
};
