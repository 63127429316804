import { Suspense } from 'react';

import { ProductType } from 'Component/Product/Product.config';
import BrowserDatabase from 'Util/BrowserDatabase';
import { lowPriorityLazy } from 'Util/Request/LowPriorityRender';

export const PARENT_PRODUCT = 'PARENT_PRODUCT';

export const ProductReviewRating = lowPriorityLazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "product-misc" */ 'Component/ProductReviewRating'),
);

/** @namespace Efex/MorhanePdpReviews/Plugin/Component/Product/renderRatingSummary */
export const renderRatingSummary = (args, callback, instance) => {
    if (instance.className === 'ProductActions') {
        const { product } = instance.props;
        const reviewSummary = getReviewSummary(product);

        if (reviewSummary !== false) {
            return (
                <Suspense fallback={ null }>
                    <ProductReviewRating
                      summary={ reviewSummary.rating_summary || 0 }
                      count={ reviewSummary.review_count }
                    />
                </Suspense>
            );
        }
    }

    return callback.apply(instance, args);
};

/** @namespace Efex/MorhanePdpReviews/Plugin/Component/Product/getReviewSummary */
const getReviewSummary = (product = {}) => {
    const {
        type_id: type,
        parent_id,
    } = product;

    if ((type === ProductType.SIMPLE || type === ProductType.VIRTUAL) && parent_id !== null) {
        const { parentID, parentProduct: cachedParentProduct } = BrowserDatabase.getItem(PARENT_PRODUCT) || {};

        if (parent_id === parentID) {
            const {
                variants = [],
            } = cachedParentProduct;

            const summary = [];
            const reviewsCount = variants.reduce((acc, variant) => {
                if (!variant.rating_summary || Number(variant.rating_summary) === 0) {
                    return acc;
                }

                summary.push(Number(variant.rating_summary));
                return {
                    review_count: acc.review_count + Number(variant.review_count),
                    rating_summary: (acc.rating_summary + Number(variant.rating_summary)),
                };
            }, { review_count: 0, rating_summary: 0 });
        
            const ratingSummary = summary.length ? reviewsCount.rating_summary / summary.length : 0;
            return {
                review_count: reviewsCount.review_count,
                rating_summary: ratingSummary,
            }
        }
    }

    return false;
};

export default {
    'Component/Product/Component': {
        'member-function': {
            renderRatingSummary
        }
    },
};
