import { GQLProductStockStatus } from 'Type/Graphql.type';

// /** @namespace Efex/MorhanePdpVariants/Plugin/Compoennt/ProductConfigurableAttributeDropdown/ProductConfigurableAttributeDropdownContainer/containerProps */
export const containerProps = (args, callback, instance) => {
    const { parameters, inStock, getConfigurableAttributeData } = instance.props;

    return {
        ...callback(...args),
        parameters,
        inStock,
        getConfigurableAttributeData,
    };
};

// /** @namespace Efex/MorhanePdpVariants/Plugin/Compoennt/ProductConfigurableAttributeDropdown/ProductConfigurableAttributeDropdownContainer/_getSelectOptions */
export const _getSelectOptions = (args, callback, instance) => {
    const {
        option: {
            attribute_options = {},
            attribute_code,
        },
        getIsConfigurableAttributeAvailable,
    } = instance.props;

    if (!attribute_options) {
        // eslint-disable-next-line no-console
        console.warn(`Please make sure "${attribute_code}" is visible on Storefront.`);

        return [];
    }

    return Object.values(attribute_options)
        .reduce((acc, option) => {
            const { value } = option;

            const { isAvailable, status } = getIsConfigurableAttributeAvailable({
                attribute_code,
                attribute_value: value,
            });

            if (status) {
                return [...acc, {
                    ...option,
                    id: value,
                    isAvailable,
                }];
            }

            return acc;
        }, []);
};

export default {
    'Component/ProductConfigurableAttributeDropdown/Container': {
        'member-function': {
            containerProps,
            _getSelectOptions,
        }
    },
};
