import { PureComponent, Suspense } from 'react';

import './LandingPage.style';
import '@scandipwa/scandipwa/src/component/CategoryDetails/CategoryDetails.style';
import '@scandipwa/scandipwa/src/route/CategoryPage/CategoryPage.style';
import Html from '@scandipwa/scandipwa/src/component/Html';
import CategoryFilterOverlay from '@scandipwa/scandipwa/src/component/CategoryFilterOverlay';
import Loader from '@scandipwa/scandipwa/src/component/Loader';
import ContentWrapper from '@scandipwa/scandipwa/src/component/ContentWrapper';
import CategoryItemsCount from '@scandipwa/scandipwa/src/component/CategoryItemsCount';
import { CategoryPageLayout, LAYOUT_KEY } from '@scandipwa/scandipwa/src/route/CategoryPage/CategoryPage.config';
import CategoryProductList from '@scandipwa/scandipwa/src/component/CategoryProductList';
import TextPlaceholder from '@scandipwa/scandipwa/src/component/TextPlaceholder';
import { TextPlaceHolderLength } from '@scandipwa/scandipwa/src/component/TextPlaceholder/TextPlaceholder.config';
import CategorySort from '@scandipwa/scandipwa/src/component/CategorySort';
import { isCrawler, isSSR } from '@scandipwa/scandipwa/src/util/Browser';
import { getQueryParam } from '@scandipwa/scandipwa/src/util/Url';
import history from '@scandipwa/scandipwa/src/util/History';
import GridIcon from '@scandipwa/scandipwa/src/component/GridIcon';
import ListIcon from '@scandipwa/scandipwa/src/component/ListIcon';
import BrowserDatabase from '@scandipwa/scandipwa/src/util/BrowserDatabase';
import FilterIcon from '@scandipwa/scandipwa/src/component/FilterIcon';
import Image from '@scandipwa/scandipwa/src/component/Image';
import { ImageRatio } from '@scandipwa/scandipwa/src/component/Image/Image.type';

export class LandingPage extends PureComponent {
    static defaultProps = {
        search: '',
    };

    state = {
        categoryLoaded: false,
        activeLayoutType: undefined
    }

    static getDerivedStateFromProps(props) {
        const {
            isMobile,
            defaultPlpType,
            selectedLayoutType,
        } = props;

        /*
        * Use stored plpType from the BrowserDatabase
        * if there is one
        */
        const storedPlpType = BrowserDatabase.getItem(LAYOUT_KEY) || selectedLayoutType;

        if (storedPlpType) {
            const activeLayoutType = isMobile
                ? CategoryPageLayout.GRID
                : storedPlpType || defaultPlpType;

            return { activeLayoutType };
        }

        const activeLayoutType = isMobile
            ? CategoryPageLayout.GRID
            : selectedLayoutType || defaultPlpType;

        return { activeLayoutType };
    }

    componentDidUpdate() {
        const { landinpage } = this.props;
        const { categoryLoaded } = this.state;

        if (landinpage && !categoryLoaded) {
            this.setState({
                categoryLoaded: true
            });

            // this.requestProductList();
        }
    }

    requestProductList() {
        const { requestProductList, requestProductListInfo, filter, selectedSort } = this.props;
        const { location } = history;

        const pagesize = getQueryParam('page', location) || 1;

        requestProductList({
            "isNext": false,
            "noAttributes": false,
            "noVariants": false,
            "args": {
                "sort": selectedSort,
                "filter": filter,
                "search": "",
                "pageSize": 24,
                "currentPage": pagesize
            },
            "isPlp": true
        });

        requestProductListInfo({
            "args": {
                "filter": filter,
                "search": "",
            },
        });
    }

    renderFilterButtonPlaceholder() {
        return (
            <p block="CategoryPage" elem="FilterButtonPlaceholder">
                <TextPlaceholder length={TextPlaceHolderLength.SHORT} />
            </p>
        );
    }

    renderPlaceholder(block) {
        return (
            <>
                <div block={block} elem="SwatchList">
                    <div block={block} elem="Placeholder" />
                    <div block={block} elem="Placeholder" />
                    <div block={block} elem="Placeholder" />
                </div>
                <Loader isLoading />
            </>
        );
    }

    renderFilterPlaceholder() {
        return (
            <div block="CategoryPage" elem="PlaceholderWrapper">
                <div block="CategoryPage" elem="PlaceholderContainer">
                    <h3 block="CategoryPage" elem="PlaceholderHeading">
                        {__('Shopping Options')}
                    </h3>
                    <div block="CategoryPage" elem="PlaceholderList">
                        <div block="CategoryPage" elem="PlaceholderListItem" />
                        <div block="CategoryPage" elem="PlaceholderListItem" />
                        <div block="CategoryPage" elem="PlaceholderListItem" />
                    </div>
                    <Loader />
                </div>
            </div>
        );
    }

    renderPageTitle() {
        const { landinpage } = this.props;
        return (
            <h1 block="CategoryDetails" elem="Heading">{landinpage?.layout_heading}</h1>
        )
    }

    renderLayoutImage() {
        const { landinpage } = this.props;
        let imgae = landinpage?.layout_file;

        if (!landinpage?.layout_file) {
            return null;
        }

        if (landinpage?.layout_file?.indexOf("/") === 0) {
            imgae = landinpage?.layout_file?.replace("/", "");
        }

        return (
            <Image
                src={imgae}
                alt={landinpage?.layout_file_alt}
                mix={{ block: 'CategoryDetails', elem: 'Picture' }}
                ratio={ImageRatio.IMG_CUSTOM}
            />
        )
    }

    renderLayoutTopDescription() {
        const { landinpage } = this.props;

        if (!landinpage?.layout_top_description) {
            return null;
        }

        return (
            <Html content={landinpage?.layout_top_description} />
        )
    }

    renderLayoutBottomDescription() {
        const { landinpage } = this.props;

        if (!landinpage?.layout_bottom_description) {
            return null;
        }

        return (
            <Html content={landinpage?.layout_bottom_description} />
        )
    }

    renderLayoutStaticTop() {
        const { landinpage } = this.props;

        if (!landinpage?.layout_static_top) {
            return null;
        }

        return (
            <Html content={landinpage?.layout_static_top} />
        )
    }

    renderLayoutStaticBottom() {
        const { landinpage } = this.props;

        if (!landinpage?.layout_static_bottom) {
            return null;
        }

        return (
            <Html content={landinpage?.layout_static_bottom} />
        )
    }

    renderLayoutButtons() {
        const {
            onGridButtonClick,
            onListButtonClick,
            isMobile
        } = this.props;

        const { activeLayoutType } = this.state;

        if (isMobile) {
            return null;
        }

        return (
            <div block="CategoryPage" elem="LayoutButtons">
                <button
                    onClick={onGridButtonClick}
                    mix={{
                        block: CategoryPageLayout.GRID,
                        mods: { isActive: activeLayoutType === CategoryPageLayout.GRID || (!activeLayoutType) },
                    }}
                    aria-label="grid"
                >
                    <Suspense fallback={null}><GridIcon isActive={activeLayoutType === CategoryPageLayout.GRID || (!activeLayoutType)} /></Suspense>
                </button>
                <button
                    onClick={onListButtonClick}
                    mix={{
                        block: CategoryPageLayout.LIST,
                        mods: { isActive: activeLayoutType === CategoryPageLayout.LIST },
                    }}
                    aria-label="list"
                >
                    <Suspense fallback={null}><ListIcon isActive={activeLayoutType === CategoryPageLayout.LIST} /></Suspense>
                </button>
            </div>
        );
    }

    renderFiltersCount() {
        const { appliedFiltersCount } = this.props;

        if (!appliedFiltersCount) {
            return null;
        }

        return (
            <span block="CategoryPage" elem="Subheading">
                {` (${appliedFiltersCount})`}
            </span>
        );
    }

    renderFilterButton() {
        const {
            isContentFiltered,
            totalPages,
            onFilterButtonClick,
        } = this.props;

        if ((!isContentFiltered && totalPages === 0)) {
            return null;
        }

        return (
            <button
                block="CategoryPage"
                elem="Filter"
                onClick={onFilterButtonClick}
            >
                <Suspense fallback={<div block="CategoryPage" elem="FilterPlaceholder" />}>
                    <FilterIcon />
                </Suspense>
                <span>{__('Filters')}</span>
                {this.renderFiltersCount()}
            </button>
        );
    }

    renderItemsCount(isVisibleOnMobile = false) {
        const { isMobile } = this.props;

        if ((isVisibleOnMobile && !isMobile) || (!isVisibleOnMobile && isMobile)) {
            return null;
        }

        return (
            <CategoryItemsCount
                isMatchingListFilter={true}
            />
        );
    }

    renderCategorySort() {
        const {
            sortFields,
            selectedSort,
            onSortChange,
        } = this.props;

        const { options = [] } = sortFields;
        const updatedSortFields = options.map(({ value: id, label }) => ({ id, label }));
        const { sortDirection, sortKey } = selectedSort;

        return (
            <Suspense fallback={<div block="CategoryPage" elem="CategorySortPlaceholder" />}>
                <CategorySort
                    isCurrentCategoryLoaded={true}
                    isMatchingInfoFilter={true}
                    onSortChange={onSortChange}
                    sortFields={updatedSortFields}
                    sortKey={sortKey}
                    sortDirection={sortDirection}
                />
            </Suspense>
        );
    }

    renderCategoryProductList() {
        const {
            filter,
            search,
            selectedSort,
            selectedFilters,
        } = this.props;

        const { activeLayoutType } = this.state;

        return (
            <div
                block="CategoryPage"
                elem="ProductListWrapper"
                mods={{ isPrerendered: isSSR() || isCrawler() }}
            >
                {this.renderItemsCount(true)}
                <CategoryProductList
                    filter={filter}
                    search={search}
                    sort={selectedSort}
                    selectedFilters={selectedFilters}
                    isCurrentCategoryLoaded={true}
                    isMatchingListFilter={false}
                    isMatchingInfoFilter={true}
                    layout={activeLayoutType || CategoryPageLayout.GRID}
                />
            </div>
        );
    }

    renderFilterOverlay() {
        const {
            filters,
            selectedFilters,
        } = this.props;

        return (
            <Suspense fallback={this.renderFilterPlaceholder() || null}>
                <CategoryFilterOverlay
                    availableFilters={filters}
                    customFiltersValues={selectedFilters}
                    isMatchingInfoFilter={true}
                    isCategoryAnchor={true}
                    isSearchPage={false}
                    renderPlaceholder={this.renderPlaceholder}
                />
            </Suspense>
        )
    }

    renderCategoryDetails() {
        const { isMobile, landinpage } = this.props;

        if (isMobile || (!landinpage?.layout_static_top && !landinpage?.layout_top_descriptionc && !landinpage?.layout_heading)) {
            return null;
        }

        return (
            <article class="CategoryDetails">
                <div class="CategoryDetails-Description">
                    {this.renderPageTitle()}
                    {landinpage?.layout_top_description && <div className='LanfingPage-LayoutTopDescription'>
                        {this.renderLayoutTopDescription()}
                    </div>}
                    {landinpage?.layout_static_top && <div className='LanfingPage-LayoutStaticTop'>
                        {this.renderLayoutStaticTop()}
                    </div>}
                </div>
                {this.renderLayoutImage()}
            </article>
        )
    }

    renderMiscellaneous() {
        return (
            <aside block="CategoryPage" elem="Miscellaneous">
                {this.renderItemsCount()}
                <div
                    block="CategoryPage"
                    elem="MiscellaneousLayoutWrapper"
                >
                    <div
                        block="CategoryPage"
                        elem="LayoutWrapper"
                        mods={{ isPrerendered: isSSR() || isCrawler() }}
                    >
                        {this.renderLayoutButtons()}
                        {this.renderCategorySort()}
                    </div>
                    <div
                        block="CategoryPage"
                        elem="LayoutWrapper"
                        mods={{ isPrerendered: isSSR() || isCrawler() }}
                    >
                        {this.renderFilterButton()}
                    </div>
                </div>
            </aside>
        )
    }

    renderBottomBlocks() {
        const { isMobile, landinpage } = this.props;

        if (isMobile || (!landinpage?.layout_static_bottom && !landinpage?.layout_bottom_description)) {
            return null;
        }

        return (<>
            <article class="CategoryDetails">
                <div class="CategoryDetails-Description">
                    <div className='LandingPage-Bottom-Details'>
                        {landinpage?.layout_static_bottom && <div className='LanfingPage-LayoutStaticBottom'>
                            {this.renderLayoutStaticBottom()}
                        </div>}

                        {landinpage?.layout_bottom_description && <div className='LanfingPage-LayoutBottomDescription'>
                            {this.renderLayoutBottomDescription()}
                        </div>}
                    </div>
                </div>
            </article>
        </>
        )
    }

    renderContent() {
        const { landinpage } = this.props;

        if (!landinpage?.page_id) {
            return null;
        }

        return (
            <>
                {this.renderFilterOverlay()}
                {this.renderCategoryDetails()}
                {this.renderMiscellaneous()}
                {this.renderCategoryProductList()}
                {this.renderBottomBlocks()}
            </>
        );
    }

    render() {
        return (
            <main
                block="CategoryPage"
            >
                <ContentWrapper
                    wrapperMix={{
                        block: 'CategoryPage',
                        elem: 'Wrapper',
                    }}
                    label="Category page"
                >
                    {this.renderContent()}
                </ContentWrapper>
            </main>
        );
    }
}

export default LandingPage;
