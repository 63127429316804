/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    getAddToCartData,
    getCartData,
    getRemoveFromCartData,
} from '../data/cart';
import {
    GTM_EVENT_KEY_ADD_TO_CART,
    GTM_EVENT_KEY_REMOVE_FROM_CART,
    GTM_EVENT_KEY_VIEW_CART,
} from '../util/events';
import { pushToDataLayer } from '../util/push';

/** @namespace Scandiweb/Gtm/Event/Cart/fireAddToCartEvent */
export const fireAddToCartEvent = async (item, currencyCode) => {
    const cartData = await getAddToCartData(item, currencyCode);
    pushToDataLayer({
        event: GTM_EVENT_KEY_ADD_TO_CART,
        ecommerce: {
            ...cartData,
        },
    });
};

/** @namespace Scandiweb/Gtm/Event/Cart/fireRemoveFromCartEvent */
export const fireRemoveFromCartEvent = async (item, currencyCode) => {
    // ^^^ not using debounce, as many cart edits may happen at once
    const cartData = await getRemoveFromCartData(item, currencyCode);
    pushToDataLayer({
        event: GTM_EVENT_KEY_REMOVE_FROM_CART,
        ...cartData,
    });
};

/** @namespace Scandiweb/Gtm/Event/Cart/fireViewCartEvent */
export const fireViewCartEvent = async (items, cartSubtotal, currencyCode) => {
    // ^^^ not using debounce, as many cart edits may happen at once
    const cartData = await getCartData(items, currencyCode);
    pushToDataLayer({
        event: GTM_EVENT_KEY_VIEW_CART,
        ecommerce: {
            ...cartData,
        },
    });
};
