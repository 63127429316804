import FieldImageSelect from '../../../component/FieldImageSelect';

/** @namespace Efex/MorhanePdpVariants/Plugin/Component/FieldSelect/renderNativeOption */
const renderNativeOption = (option, instance) => {
    const {
        id,
        value,
        disabled,
        label,
        subLabel = '',
        isAvailable = true,
    } = option;

    const { isDisabled } = instance.props;

    return (
        <option
          key={ id }
          id={ String(id) }
          value={ value }
          disabled={ disabled || isDisabled || !isAvailable }
        >
            { `${label} ${subLabel}` }
        </option>
    );
}

/** @namespace Efex/MorhanePdpVariants/Plugin/Component/FieldSelect/renderNativeSelect */
export const renderNativeSelect = (args, callback, instance) => {
    const {
        setRef,
        attr,
        events,
        isDisabled,
        options,
        handleSelectListOptionClick,
        isSelectedOptionAvailable,
    } = instance.props;

    const imgSrc = attr.value ? options.find((option) => option.value === attr.value ).image : '';

    return (
        <select
          block="FieldSelect"
          elem="Select"
          mods={ { isDisabled: !isSelectedOptionAvailable } }
          ref={ (elem) => setRef(elem) }
          disabled={ isDisabled }
          { ...attr }
          { ...events }
          onChange={ handleSelectListOptionClick }
          style={ imgSrc ? {
            backgroundImage: `url(${imgSrc})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "20px center",
            backgroundSize: "25px 30px",
            paddingLeft: "55px",
          } : {} }
        >
            { options.map((option) => renderNativeOption(option, instance)) }
        </select>
    );
};

/** @namespace Efex/MorhanePdpVariants/Plugin/Component/FieldSelect/renderOption */
export const renderOption = (args, callback, instance) => {
    const option = args[0];

    const {
        isExpanded,
        handleSelectListOptionClick,
    } = instance.props;

    return <FieldImageSelect option={ option } isExpanded={ isExpanded } handleSelectListOptionClick={ handleSelectListOptionClick } />;
};

export default {
    'Component/FieldSelect/Component': {
        'member-function': {
            renderOption,
            renderNativeSelect,
        },
    },
};
