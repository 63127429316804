import { ReactElement } from '@scandipwa/scandipwa/src/type/Common.type';
import { Suspense } from 'react';

import {
    CategoryPage,
    CmsPage,
    NoMatch,
    ProductPage,
    UrlRewritesComponent as SourceUrlRewritesComponent,
} from 'SourceRoute/UrlRewrites/UrlRewrites.component';

import LandingPage from '../../../packages/@efex/morhane-xlanding/src/plugin/component';
import { UrlRewritePageType } from './UrlRewrites.config';

import './UrlRewrites.override.style';

export {
    ProductPage,
    CategoryPage,
    CmsPage,
    NoMatch,
};

/** @namespace Celtik/Route/UrlRewrites/Component */
export class UrlRewritesComponent extends SourceUrlRewritesComponent {
    renderLandingPage(): ReactElement {
        const { props, id }: any = this.props;
        const {
            match,
        } = props;

        return (
            <Suspense fallback={ this.renderDefaultPage() }>
                <LandingPage
                  match={ match }
                  id={ id }
                />
            </Suspense>
        );
    }

    renderContent(): ReactElement {
        const { type } = this.props;

        switch (type) {
        case UrlRewritePageType.PRODUCT:
            return this.renderProductPage();
        case UrlRewritePageType.CMS_PAGE:
            return this.renderCmsPage();
        case UrlRewritePageType.CATEGORY:
            return this.renderCategoryPage();
        case UrlRewritePageType.NOTFOUND:
            return this.renderNoMatch();
        case 'AMASTY_XLANDING_PAGE':
            return this.renderLandingPage();
        default:
            return this.renderDefaultPage();
        }
    }
}

export default UrlRewritesComponent;
