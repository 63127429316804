import Field from 'Component/Field';
import { FieldType } from 'Component/Field/Field.config';
import VariantSelectOption from '../../../component/VariantSelectOption';

const renderVariantSelectOptions = (option, instance) => {
    const {
        selectName,
        onChange,
        parameters,
    } = instance.props;

    const {
        id,
        isAvailable,
        label,
        value,
    } = option;

    return (
        <VariantSelectOption
          label={ label }
          value={ value }
          isAvailable={ isAvailable }
          key={ id }
          isSelected={ parameters[selectName] === value }
          onChange={ onChange }
        />
    );
}

/** @namespace Efex/MorhanePdpVariants/Plugin/Component/ProductConfigurableAttributeDropdown/render */
export const render = (args, callback, instance) => {
    const {
        selectOptions,
        selectValue,
        selectName,
        selectLabel,
        onChange: onValueChange,
        handleShakeAnimationEnd,
        isUnselected,
    } = instance.props;

    if (selectOptions.length <= 6) {
        return selectOptions.map((option) => renderVariantSelectOptions(option, instance));
    }

    const onChange = (value) => {
        if(value !== selectValue) {
            onValueChange(value);
        }
    };

    return (
        <Field
          type={ FieldType.SELECT }
          attr={ {
              id: selectName,
              name: selectName,
              defaultValue: selectValue,
              selectPlaceholder: __('Choose %s...', selectLabel.toLowerCase()),
              onAnimationEnd: handleShakeAnimationEnd,
          } }
          events={ {
            onChange,
          } }
          mix={ { block: 'ProductConfigurableAttributeDropdown', mods: { isUnselected } } }
          options={ selectOptions }
          changeValueOnDoubleClick={ false }
        />
    );
};

export default {
    'Component/ProductConfigurableAttributeDropdown/Component': {
        'member-function': {
            render,
        },
    },
};
