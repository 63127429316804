import { fetchQuery } from '@scandipwa/scandipwa/src/util/Request/Query';

import SeoConfigurationQuery from '../query/SeoConfiguration.query';
import { updateSeoConfigurations } from '../store/SeoConfiguration/SeoConfiguration.action';

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        updateSeoConfigs: (seoConfigurations) => dispatch(updateSeoConfigurations(seoConfigurations)),
    };
};

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        configurations: state.ConfigReducer,
    };
};

const componentDidMount = (args, callback, instance) => {
    const { updateSeoConfigs } = instance.props;
    callback(...args);

    fetchQuery(SeoConfigurationQuery.seoConfigurations())
        .then((response) => {
            updateSeoConfigs(response?.seoInfo);
        }).catch((e) => console.log(e));
};

export default {
    'Component/Router/Container': {
        'member-function': {
            componentDidMount,
        },
    },
    'Component/Router/Container/mapDispatchToProps': {
        function: mapDispatchToProps,
    },

    'Component/Router/Container/mapStateToProps': {
        function: mapStateToProps,
    },
};
